import React from 'react';
import PropTypes from 'prop-types';
import {compose} from '../../Util';
import {withStatic, createContainer} from '../../Hoc.jsx';
import style from '../../../css/TokenBackground.css';
import classNames from 'classnames/bind';

const cx = classNames.bind(style);

const TokenBackground = createContainer({
    type: 'div',
    displayName: 'TokenBackground',
    className: cx('TokenBackground-container'),
});

const TokenBackgroundHeader = createContainer({
    type: 'div',
    displayName: 'TokenBackgroundHeader',
    className: cx('TokenBackground-header'),
});

const TokenBackgroundTitle = ({logo, text, alignAtCenter}) => {
    if (!logo && text) {
        return (
            <div className={cx({
                'TokenBackground-header-title': true,
                'TokenBackground-header-title--center': !!alignAtCenter
            })}>
                <span className={cx({
                    'TokenBackground-header-title-text': true,
                    'TokenBackground-header-title-text--center': !!alignAtCenter,
                })}>
                    {text}
                </span>
            </div>
        );
    }
    return (
        <div className={cx({
            'TokenBackground-header-title': true,
            'TokenBackground-header-title--center': !!alignAtCenter
        })}>
            { logo && (
                <img
                    src={logo}
                    className={cx('TokenBackground-logo')}
                    alt={text ? text + 'logo' : 'token logo'}
                />
            )}
        </div>
    );
};

TokenBackgroundTitle.propTypes = {
    logo: PropTypes.string,
    text: PropTypes.string,
    noPrepend: PropTypes.bool,
};

const TokenBackgroundContent = createContainer({
    type: 'div',
    displayName: 'TokenBackgroundContent',
    className: cx('TokenBackground-content'),
});

const TokenBackgroundFooter = createContainer({
    type: 'div',
    displayName: 'TokenBackgroundFooter',
    className: cx('TokenBackground-footer'),
});

export default compose(
    withStatic({
        Header: TokenBackgroundHeader,
        Title: TokenBackgroundTitle,
        Content: TokenBackgroundContent,
        Footer: TokenBackgroundFooter,
    }),
)(TokenBackground);
